<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Gelir & Gider Tipi Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Gelir & Gider Tipi Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Gelir & Gider Tipleri",
          href: "/incomeexpensetype"
        },
        {
          text: "Gelir & Gider Tipi Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: {
        name: "",
        type: "",
      },
      TypeOptions:[
        {value: 1, text: "Gelir"},
        {value: 2, text: "Gider"}
      ],
    };
  },
  created(){
    this.getData()
  },
  methods: {
    getData() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/incomeexpensetype/${itemId}`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = response.data?.data;
          this.balance = response.data?.data.balance;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showAlertSuccess(){
      this.isAlertVisibleSuccess = true
    },
    showAlertDanger(){
      this.isAlertVisibleDanger = true
    },
    update() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/incomeexpensetype/${itemId}`;
      const data = {
        name: this.values.name,
        type: this.values.type,
        isActive: 1
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.getData();
          this.showAlertSuccess();
        })
        .catch(() => {
          this.showAlertDanger();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class>
          <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
          <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="update">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="name">Adı:</label>
                    <input
                      v-model="values.name"
                      type="text"
                      class="form-control"
                      id="name"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="type">Tipi:</label>
                    <b-form-select
                      v-model="values.type"
                      :options="TypeOptions"
                      class="form-control"
                      id="type"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Güncelle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
